import topic from '../topic';
import { getNoCatch, postNoCatch } from '@api/request';

export const tranAPI = {
  getTrans: topic.tranTopic + '/get_trans',
  getTranById: topic.tranTopic + '/get_tran_by_id',
  deleteTranByIds: topic.tranTopic + '/delete_tran_by_ids',
  editTran: topic.tranTopic + '/edit_tran',
  getTranSonTable: topic.tranTopic + '/get_tran_son_table',
  addTran: topic.tranTopic + '/add_tran',
  syncProdPrice: topic.tranTopic + '/sync_prod_price',
  // getTranPartsByCustId: topic.tranTopic + '/get_tran_parts_by_cust_id',
  exportTranPart: topic.tranTopic + '/pdf/export_tran_part',
  export_tran_part_34: topic.tranTopic + '/pdf/export_tran_part_34',
  exportPartPack: topic.tranTopic + '/pdf/export_part_pack',
  exportPartPackAll34: topic.tranTopic + '/pdf/exportPartPackAll34',
  exportGoods: topic.tranTopic + '/pdf/export_goods',
  exportSett: topic.tranTopic + '/pdf/export_sett',
  exportSettPack: topic.tranTopic + '/pdf/export_sett_pack',
  exportInspInvo: topic.tranTopic + '/pdf/export_insp_invo',
  exportInspScon: topic.tranTopic + '/pdf/export_insp_scon',
  exportInspPack: topic.tranTopic + '/pdf/export_insp_pack',
  exportPartDeta: topic.tranTopic + '/pdf/export_part_deta',
  exportTranMtrb: topic.tranTopic + '/pdf/export_tran_mtrb',
  exportGoodsCustom: topic.tranTopic + '/pdf/exportGoodsCustom',
  getTranByCustId: topic.tranTopic + '/get_tran_by_cust_id',
  getTranPartsByCustId: topic.tranTopic + '/get_tran_parts_by_cust_id',
  getTranProdsByCustId: topic.tranTopic + '/get_tran_prods_by_cust_id',
  getTranFeesByCustId: topic.tranTopic + '/get_tran_fees_by_cust_id',
  getAllTransV1: topic.tranTopic + '/get_all_trans_v1',
  exportHoins: topic.tranTopic + '/pdf/exportHoins',
  export_tran_cost: params => getNoCatch(`${topic.tranTopic}/pdf/export_tran_cost`, params),
  get_all_trans_page: params => getNoCatch(`${topic.tranTopic}/get_all_trans_page`, params),
  get_fees_sum: params => getNoCatch(`${topic.tranTopic}/get_fees_sum`, params),
  get_tran_import_mtrb_list: params => getNoCatch(`${topic.tranTopic}/get_tran_import_mtrb_list`, params),
  getTranPartByPack: params => getNoCatch(`${topic.tranTopic}/getTranPartByPack`, params),
  getTranPriceForSh: params => getNoCatch(`${topic.tranTopic}/getTranPriceForSh`, params),
  getTranPriceForHk: params => getNoCatch(`${topic.tranTopic}/getTranPriceForHk`, params),
  getSconProdList: topic.tranTopic + '/getSconProdList',
  getTranPartGroup: topic.tranTopic + '/getTranPartGroup',
  getTranMonthTable: params => getNoCatch(`${topic.tranTopic}/getTranMonthTable`, params),
  exportTranMonthTable: params => getNoCatch(`${topic.tranTopic}/exportTranMonthTable`, params),
  getTranProdTable: params => getNoCatch(`${topic.tranTopic}/getTranProdTable`, params),
  exportgetTranProdTable: params => getNoCatch(`${topic.tranTopic}/exportgetTranProdTable`, params),
  getTranListByRichMax: params => getNoCatch('/api/richErpTran/getTranListByRichMax', params),
  addTranByRichMax: params => postNoCatch('/api/richErpTran/addTranByRichMax', params),
  getTranCusts: topic.tranTopic + '/getTranCusts'
};
